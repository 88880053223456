import { getAge } from "../../utils/dateHelper"

// precisa refatorar e separar cada protocolo em arquivos diferentes
export default {
  data() {
    return {
      protocolsInformation: [
        {
          key: 'essilor',
          name: 'Lentes para controle de miopia',
          manufacturer: 'Lentes Essilor® Stellest®',
          instructions: [
            'Essas lentes são um tratamento para desacelerar a progressão da miopia e devem ser usadas por no mínimo 12 horas por dia, em todas as atividades.',
            'Aumentar o tempo de atividades ao ar livre durante o dia (pelo menos 2 horas por dia)',
            'Reduzir o tempo de telas e lazer com atividades de perto (a menos de 30 cm) e fazer pausas a cada 20 minutos.',
            'É necessária a avaliação a cada 6 meses com o seu oftalmologista.'
          ]
          
        },
        // {
        //   key: 'hoya',
        //   name: 'Controle de progressão de miopia',
        //   manufacturer: 'Lentes Miyosmart Hoya',
        //   instructions: [
        //     'Uso das lentes Miyosmart Hoya em tempo integral, exceto durante o banho e para dormir.'
        //   ]
        // }

      ],
      protocolsRecomendations: {
        essilor: {
          recomendations: [
            {
              name: 'Resultados esperados com o tratamento, baseado em estudos clínicos:', description: 'As lentes Essilor® Stellest® retardam a progressão da miopia em 67%, em média, em comparação com as lentes de visão simples, quando usadas 12 horas por dia. 90% das crianças estão totalmente adaptadas em 3 dias e 100% das crianças se adaptam em 1 semana.'
            },
            {
              name: 'Prescrever refração total sob cicloplegia'
            },
            {
              name: 'Crianças e adolescentes:', description: 'Pacientes de 6 a 16 anos.'
            },
            {
              name: 'Míopes:', description: 'Esférico: 0,0 a −10,0; Cilíndrico: 0,0 a −4,0; Lembrando que soma do grau esférico e cilíndrico não pode ser maior que -10,'
            },
            {
              name: 'Pré-míopes:', description: '+0,75 ou menos com fatores de risco para progressão da miopia. Cruzar com fatores de risco.'
            },
            {
              name: 'Critérios para progressão da miopia:', description: 'Erro refrativo sob cicloplegia tem um aumento maior que -0,50 D em um ano ou >= 0,50 D se e quando o comprimento axial tem um crescimento de 0,2 (a partir de 11 anos) a 0,3 mm/ano (até os 10 anos);'
            },
            {
              name: '>0,5 DE / ano* (≥0,5 DE se: início da miopia <7anos + FR)', description: ''
            },
            {
              name: '≥0,3mm / ano (<11 anos)', description: ''
            },
            {
              name: '≥0,2mm / ano (≥11 anos)', description: ''
            },
            {
              name: 'Prescrever por no mínimo 12 horas por dia'
            },
            {
              name: 'Observações adicionais:', description: 'As lentes Essilor® Stellest® podem ser recomendadas para crianças a partir de 6 anos e até 16 anos, ou até que sua miopia se estabilize, mediante decisão do oftalmologista. Casos que saiam das indicações (por exemplo: criança com 5 anos, ou maior que 16) não constituem contra-indicações. Assim, o médico deve conseguir prescrever pela plataforma, caso queira. Só não deveria aparecer como uma recomendação ativa dentro do protocolo que estabelecemos.'
            }   
          ],
          link: 'https://eyecarebi.eyecarehealth.com.br/academy/curso/551a2d88-2ffe-456a-aac2-29bacceb7453'
        },
        // hoya: {
        //   recomendations: [
        //     { name: 'Crianças: ', description: 'Pacientes de 5 a 18 anos.' },
        //     {
        //       name: 'Míopes:',
        //       description:
        //         'Ref −0.5 D a −9.50, astig até -4.00 (lembrando que soma do grau e esférico não pode ultrapassar -10.00).'
        //     },
        //     {
        //       name: 'Critérios para progressão da miopia: ',
        //       description:
        //         'Erro refrativo sob cicloplegia tem um aumento maior que -0,50 D por um ano e quando o comprimento axial tem um crescimento de 0,2 (a partir de 11 anos) a 0,3 mm/ano (até os 10 anos);'
        //     }
        //   ],
        //   link: 'https://eyecarebi.eyecarehealth.com.br/academy/curso/551a2d88-2ffe-456a-aac2-29bacceb7453'
        // }
      }
    }
  },
  methods: {
    protocolIsIndicated(keyProtocol, refractions, birthDay) {
      switch(keyProtocol) {
        // case 'hoya':
          // return this.hoyaLensesRules(refractions, getAge(birthDay))
        case 'essilor':
          return this.essilorLensesRules(refractions, getAge(birthDay))
        default:
          return false
      }
    },
    numberParse(value) {
      return value ? Number(value.replace(',', '.')) : 0
    },
    verifyProtocol(refractions, birthDay) {
      
      const patientAge = getAge(birthDay)
      return this.essilorLensesRules(refractions, patientAge)
    },
    hoyaLensesRules(refractions, patientAge) {
      
      if (patientAge >= 5 && patientAge <= 18) {
        if (!this.hasRefractions(refractions)) return false
  
        const rightEyeValidations = this.hoyaEyeValidations({
          refractionEsferica: refractions.olhoDireitoEsferico,
          refractionCilindrica: refractions.olhoDireitoCilindrico
        })
        const leftEyeValidations = this.hoyaEyeValidations({
          refractionEsferica: refractions.olhoEsquerdoEsferico,
          refractionCilindrica: refractions.olhoEsquerdoCilindrico
        })
  
        return rightEyeValidations || leftEyeValidations

      } else return false

    },

    hoyaEyeValidations(refractions) {
      if (!refractions.refractionEsferica && !refractions.refractionCilindrica)
        return false

      // miopia
      if (refractions.refractionEsferica && !refractions.refractionCilindrica) {
        return this.hasHoyaMiopiaRule(refractions.refractionEsferica)
      }

      // astigmatismo
      if (refractions.refractionCilindrica && !refractions.refractionEsferica) {
        return this.hasHoyaAstigRule(refractions.refractionCilindrica)
      }

      const totalPower = this.totalPower(
        refractions.refractionCilindrica,
        refractions.refractionEsferica
      )

      if (totalPower < -10) return false

      return (
        this.hasHoyaMiopiaRule(refractions.refractionEsferica) &&
        this.hasHoyaAstigRule(refractions.refractionCilindrica)
      )
    },
    
    hasHoyaMiopiaRule(refractionEsferica) {
      return (
        this.numberParse(refractionEsferica) >= -9.5 &&
        this.numberParse(refractionEsferica) <= -0.5
      )
    },
    hasHoyaAstigRule(refractionCilindrica) {
      return this.numberParse(refractionCilindrica) >= -4
    },

    essilorLensesRules(refractions, patientAge) {
      if (patientAge >= 6 && patientAge <= 16) {
        if (!this.hasRefractions(refractions)) return false
  
        const rightEyeValidations = this.essilorEyeValidations({
          refractionEsferica: refractions.olhoDireitoEsferico,
          refractionCilindrica: refractions.olhoDireitoCilindrico
        })
        const leftEyeValidations = this.essilorEyeValidations({
          refractionEsferica: refractions.olhoEsquerdoEsferico,
          refractionCilindrica: refractions.olhoEsquerdoCilindrico
        })

        return rightEyeValidations || leftEyeValidations

      } else return false

    },
    
    essilorEyeValidations(refractions) {
      if (!refractions.refractionEsferica && !refractions.refractionCilindrica)
        return false

      // miopia
      if (refractions.refractionEsferica && !refractions.refractionCilindrica) {
        return this.hasEssilorMiopiaRule(refractions.refractionEsferica)
      }

      // astigmatismo
      if (refractions.refractionCilindrica && !refractions.refractionEsferica) {
        return this.hasEssilorAstigRule(refractions.refractionCilindrica)
      }

      const totalPower = this.totalPower(
        refractions.refractionCilindrica,
        refractions.refractionEsferica
      )

      if (totalPower < -10) return false

      return (
        this.hasEssilorMiopiaRule(refractions.refractionEsferica) &&
        this.hasEssilorAstigRule(refractions.refractionCilindrica)
      )
    },

    hasEssilorMiopiaRule(refractionEsferica) {
      return (
        this.numberParse(refractionEsferica) >= -10 &&
        this.numberParse(refractionEsferica) <= 0
      )
    },
    hasEssilorAstigRule(refractionCilindrica) {
      return this.numberParse(refractionCilindrica) >= -4 &&
        this.numberParse(refractionCilindrica) <= 0
    },    
    
    hasRefractions(refractions) {
      return Object.values(refractions).some(field => field !== null)
    },

    totalPower(refractionEsferica, refractionCilindrica) {
      return (
        this.numberParse(refractionEsferica) +
        this.numberParse(refractionCilindrica)
      )
    }
  }
}
